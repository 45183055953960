<template functional>
  <b-badge v-if="props.count && props.count > 0" class="counter" :variant="props.variant">
    <template v-if="Number(props.count) <= Number(props.maxCount)">{{ props.count }}</template>
    <template v-else>{{ props.maxCount }}+</template>
  </b-badge>
</template>

<script>
import SCSS_VARIABLES from '@/assets/js/modules/scss-variables'

const ALLOWED_VARIANT_NAMES = Object.keys(SCSS_VARIABLES.colors.theme)

export default {
  name: 'Counter',
  props: {
    count: {
      type: [String, Number],
      default: null
    },
    maxCount: {
      type: [String, Number],
      default: 9999
    },
    variant: {
      type: String,
      default: 'primary',
      validator: value => ALLOWED_VARIANT_NAMES.includes(value)
    }
  }
}
</script>

<style lang="scss">
$counter-padding: $spacer * 0.25 !default;
$counter-font-size: 12px !default;
$counter-font-weight: $font-weight-bold !default;

$counter-offset-y: $counter-font-size * -0.1 !default;

.counter-wrapper {
  position: relative;

  .counter {
    position: absolute;
    top: $counter-offset-y;
    left: 60%;
  }
}

.counter {
  padding: $counter-padding;
  min-width: calc(1em + #{$counter-padding * 2});
  min-height: calc(1em + #{$counter-padding * 2});
  border-radius: 2em;
  font-size: $counter-font-size;
  font-weight: $counter-font-weight;
  white-space: nowrap;
}
</style>
